import { call, put, takeLatest } from 'redux-saga/effects'

import {
  AGENCY_TIMESHEET_NO_SHOW_REASON_DELETE_BEGIN,
  AGENCY_TIMESHEET_NO_SHOW_REASON_DELETE_SUCCEEDED,
} from 'actions/action-types'

import { apiRequest, handleCall } from 'api/call'

import { entityCall } from './entityCall'

export const deleteNoShowReason = ({ entity }) => {
  return new Promise((resolve, reject) => {
    const req = apiRequest({
      path: `/agency_portal/timesheets/entries/${entity.entry.id}/no_show`,
      method: 'DELETE',
    })
    handleCall(req, resolve, reject)
  })
}

export function* deleteNoShowReasonFromTimesheet(action) {
  const payload = yield call(entityCall, deleteNoShowReason, {}, action)

  yield put({
    type: AGENCY_TIMESHEET_NO_SHOW_REASON_DELETE_SUCCEEDED,
    payload,
  })

  if (action?.payload?.refetchAgencyTimesheet) {
    yield call(action?.payload?.refetchAgencyTimesheet, 'agencyTimesheets')
  }
}

export function* watchNoShowReasonDelete() {
  yield takeLatest(AGENCY_TIMESHEET_NO_SHOW_REASON_DELETE_BEGIN, deleteNoShowReasonFromTimesheet)
}
